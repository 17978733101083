import * as votingTypes from './votingTypes'
import * as teamSpaceTypes from '../teamSpace/teamSpaceTypes'
import * as userTypes from '../user/userTypes'
import {
  WS_CONNECTING,
  WS_OPEN,
  backendEnvironments,
  // INFORMATION_LEVEL,
} from '../../../services/config'
import WebSocketConnector from '../../../services/webSocketConnector'
import * as messageTypes from '../message/messageTypes'
import Api, { urls } from '../../../services/api'
// import { Alert } from 'react-native'
import { NotificationManager } from 'react-notifications'
// import NavigationService from '../../../Navigation/NavigationService';
// import analytics from '@react-native-firebase/analytics';

export function setVotingResult(result) {
  // "payload":"{\"type\":\"SET_RESULT\",\"cardGameResult\":{\"partialResults\":[{\"card\":\"FIVE\",\"degree\":1.0,\"userSelected\":[\"31e9905d\"]}],
  // \"userSelections\":{\"31e9905d\":\"FIVE\"},\"average\":5.0,\"sum\":5,\"userCnt\":1,\"voterCnt\":1,\"voterDegree\":1.0}}","type":"SET_RESULT"}
  let cardGameResult = result.cardGameResult
  let cardPercentages = new Map()
  cardGameResult.partialResults.forEach((partialResult) => {
    if (partialResult.card !== 'NULLCARD') {
      cardPercentages.set(partialResult.card, partialResult.degree)
    }
  })
  return {
    type: votingTypes.SET_VOTING_RESULT,
    payload: {
      average: cardGameResult.average,
      totalVotes: cardGameResult.voterCnt,
      participantsWithVotings: cardGameResult.userSelections,
      cardPercentages: cardPercentages,
    },
  }
}

export const selectCard = (cardToSelect) => (dispatch, getState) => {
  let selectedOfflineCardIndex
  if (cardToSelect === 'NULLCARD') {
    selectedOfflineCardIndex = undefined
  } else {
    const { selectedLayout } = getState().teamspace
    selectedOfflineCardIndex = selectedLayout.cardLayoutList.findIndex(
      (card) => card.title === cardToSelect,
    )
    if (selectedOfflineCardIndex === -1) selectedOfflineCardIndex = undefined
  }
  dispatch({
    type: votingTypes.SELECT_CARD,
    payload: selectedOfflineCardIndex,
  })
}

export const showCard = (cardToShow) => (dispatch, getState) => {
  const { selectedLayout } = getState().teamspace
  let offlineCards = selectedLayout.cardLayoutList
  let shownOfflineCard = 0
  if (cardToShow !== 'NULLCARD') {
    shownOfflineCard = offlineCards.findIndex((card) => card.title === cardToShow)
  }

  dispatch({
    type: votingTypes.SHOW_CARD,
    payload: shownOfflineCard,
  })
}

export function showScreenVoting(issue) {
  return {
    type: votingTypes.SHOW_SCREEN,
    payload: {
      screen: 'VOTING',
      issue: issue,
    },
  }
}

export const showScreenSummary = (issue) => (dispatch) => {
  dispatch({
    type: votingTypes.SHOW_SCREEN,
    payload: {
      screen: 'SUMMARY',
      issue: issue,
    },
  })
}

export function showScreenIdle() {
  return {
    type: votingTypes.SHOW_SCREEN,
    payload: {
      screen: 'IDLE',
    },
  }
}

export function enableStartButton() {
  return {
    type: votingTypes.ENABLE_START_BUTTON,
    payload: {},
  }
}

export function enableJoinButton() {
  return {
    type: votingTypes.ENABLE_JOIN_BUTTON,
    payload: {},
  }
}

export function setVotingProgress(payload) {
  return {
    type: votingTypes.SET_VOTING_PROGRESS,
    payload: {
      userAmountInEstimation: payload.userAmountInEstimation,
      userAmountHasVoted: payload.userAmountHasVoted,
      votingPercentage: payload.progressLabel,
      voters: payload.voters,
    },
  }
}

export function setLastGeneratedCorrelationId(generatedCorrelationId, request) {
  return {
    type: votingTypes.SET_LAST_CORRELATION,
    payload: {
      correlationId: generatedCorrelationId,
      request: request,
    },
  }
}

export const compareWithLastCorrelation = (receivedCorrelationId) => (dispatch, getState) => {
  if (receivedCorrelationId === getState().voting.lastCorrelationId) {
    let timeDiff = new Date().getTime() - getState().voting.lastCorrelationIdTime
    console.log(
      'request ' +
        getState().voting.lastCorrelationIdRequest +
        ' with correlationId' +
        receivedCorrelationId +
        ' returned after ' +
        timeDiff +
        ' milliseconds',
    )
    // if (!__DEV__) {
    //   analytics().logEvent('correlation_ack', {
    //     request: getState().voting.lastCorrelationIdRequest,
    //     correlationId: receivedCorrelationId,
    //     time: timeDiff,
    //   })
    // }
  }
}

export const handleAppStateChange = (currentAppState, nextAppState) => {
  return (dispatch) => {
    if (currentAppState.match(/inactive|background/) && nextAppState === 'active') {
      // do nothing - the queue updates the client to the current state
    } else if (currentAppState === 'active' && nextAppState.match(/inactive|background/)) {
      // do nothing
    }
  }
}

export const calcAndRecoverWebSocketState = () => {
  return () => WebSocketConnector?.getInstance().calcAndRecoverWebSocketState()
}

export const calcWebSocketState = () => {
  return (dispatch) => {
    const webSocketState = calcWebSocketStateImpl()
    console.log('calculated and returned webSocketState' + webSocketState)
    dispatch(connectionStatusChange(webSocketState))
    return webSocketState
  }
}

export const calcWebSocketStateImpl = () => {
  return WebSocketConnector?.getInstance().getWebSocketState()
}

function connectStart(dispatch, getState) {
  return new Promise((resolve) => {
    console.log('connect call')
    if (calcWebSocketStateImpl() !== WS_OPEN) {
      connectWebSocket(
        () => {
          dispatch(connectionStatusChange(calcWebSocketStateImpl()))
          resolve()
        },
        getState().user.cookie,
        getState().teamspace.teamSpaceUid,
      )
    } else {
      resolve()
    }
  })
}

export const userJoinedType = (userId) => {
  return {
    type: votingTypes.SESSION_USER_JOINED,
    payload: userId,
  }
}

export const connectionStatusChange = (response) => {
  return {
    type: votingTypes.CONNECTION_STATUS_CHANGE,
    payload: response,
  }
}

export const disconnectStart = () => {
  return (dispatch) => {
    console.log('disconnect call')
    if (calcWebSocketStateImpl() === WS_OPEN || calcWebSocketStateImpl() === WS_CONNECTING) {
      disconnectWebSocket(WebSocketConnector?.getInstance())
    }
    dispatch(disconnectSuccess(calcWebSocketStateImpl()))
  }
}

export const disconnectSuccess = (response) => {
  return {
    type: votingTypes.DISCONNECT_SUCCESS,
    payload: response,
  }
}

export const joinSessionStart = () => {
  return (dispatch, getState) => {
    console.log('PREV joinSession call')
    dispatch(calcWebSocketState())
    WebSocketConnector?.getInstance().resetPreviousMessageUid()
    connectStart(dispatch, getState).then(() => {
      joinSession(WebSocketConnector?.getInstance(), getState, (dispatch) => {
        dispatch(calcWebSocketState())
      })
      dispatch(joinSessionProgress())
    })
    // this method doesn't have a return dispatch, because the success or failure is determined by the server
  }
}

export const leaveSessionStart = () => {
  return async (dispatch, getState) => {
    console.log('leaveSession call')
    if (calcWebSocketStateImpl().toString() === 'OPEN') {
      await leaveSession(WebSocketConnector?.getInstance(), getState, (dispatch) => {
        dispatch(calcWebSocketState())
      })
    }
    dispatch(leaveSessionDispatch())
    dispatch(disconnectStart())
  }
}

export const leaveEstimationStart = () => {
  return (dispatch, getState) => {
    connectStart(dispatch, getState).then(() => {
      leaveEstimation(WebSocketConnector?.getInstance(), getState, (dispatch) => {
        dispatch(calcWebSocketState())
      })
    })
  }
}

const leaveSessionDispatch = () => {
  return {
    type: votingTypes.SESSION_LEAVE,
    payload: {},
  }
}

export const joinSessionProgress = () => {
  return {
    type: votingTypes.JOIN_SESSION_PROGRESS,
    payload: {},
  }
}

export const changeState = (response) => {
  return {
    type: votingTypes.CHANGE_STATE,
    payload: response,
  }
}

export const joinEstimationStart = () => {
  return (dispatch, getState) => {
    console.log('joinEstimation call')
    joinEstimation(WebSocketConnector?.getInstance(), getState, (dispatch) => {
      dispatch(calcWebSocketState())
    })
    const voting = {
      webSocketState: WebSocketConnector?.getInstance().getWebSocketState().toString(),
    }
    dispatch(joinEstimationSuccess(voting))
  }
}

export const joinEstimationSuccess = (response) => {
  return {
    type: votingTypes.ESTIMATION_JOIN_SUCCESS,
    payload: response,
  }
}

export const changeIssueNameStart = (issue) => {
  return (dispatch, getState) => {
    console.log('start change issue name')
    changeIssueName(WebSocketConnector?.getInstance(), getState, issue, (dispatch) => {
      dispatch(calcWebSocketState())
    })
  }
}

export const estimationStart = (issue, wantsToVote) => {
  return (dispatch, getState) => {
    console.log('start Estimation call')
    startEstimation(WebSocketConnector?.getInstance(), getState, issue, wantsToVote, (dispatch) => {
      dispatch(calcWebSocketState())
    })
    // this method doesn't have a return dispatch, because the success or failure is determined by the server
    dispatch(votingStartDispatch())
  }
}

export const votingStartDispatch = () => {
  return {
    type: votingTypes.VOTING_START_PROGRESS,
  }
}
export const startVotingSuccess = (response) => {
  return {
    type: votingTypes.VOTING_START_SUCCESS,
    payload: response,
  }
}

export const finishEstimationStart = (chosenCardIndex) => (dispatch, getState) => {
  let chosenCardTitle
  if (chosenCardIndex === -1) {
    chosenCardTitle = 'NULLCARD'
  } else {
    const { selectedLayout } = getState().teamspace
    chosenCardTitle = selectedLayout.cardLayoutList[chosenCardIndex].title
  }
  console.log('finishEstimation call')
  finishEstimation(WebSocketConnector?.getInstance(), getState, chosenCardTitle, (dispatch) => {
    dispatch(calcWebSocketState())
  })
}

export const finishVotingStart = () => {
  return (dispatch, getState) => {
    finishVoting(WebSocketConnector?.getInstance(), getState, (dispatch) => {
      dispatch(calcWebSocketState())
    })
    dispatch({ type: votingTypes.VOTING_START_PROGRESS, payload: {} })
  }
}

export const finishVotingSuccess = (response) => {
  return {
    type: votingTypes.VOTING_FINISH_SUCCESS,
    payload: response,
  }
}

export const finishEstimationSuccess = (response) => {
  return {
    type: votingTypes.ESTIMATION_FINISH_SUCCESS,
    payload: response,
  }
}

export const userCastVoteStart = (selectedCard) => (dispatch, getState) => {
  const { selectedLayout } = getState().teamspace
  let cardIndex = selectedLayout.cardLayoutList.findIndex((card) => card.title === selectedCard)
  console.log(cardIndex, selectedCard)
  userCastVote(WebSocketConnector?.getInstance(), getState, selectedCard, (dispatch) => {
    dispatch(calcWebSocketState())
  })
  dispatch(userCastVoteProgress(cardIndex))
}

export const presetFinalResultStart = (selectedCardIndex) => (dispatch, getState) => {
  const { selectedLayout } = getState().teamspace
  let selectedCard = selectedLayout.cardLayoutList[selectedCardIndex].title
  preselectFinalResult(WebSocketConnector?.getInstance(), getState, selectedCard, (dispatch) => {
    dispatch(calcWebSocketState())
  })
  dispatch(preselectFinalResultProgress(selectedCard))
}

const preselectFinalResultProgress = (cardIndex) => {
  return {
    type: votingTypes.PRESELECT_FINAL_RESULT_PROGRESS,
    payload: cardIndex,
  }
}

function preselectFinalResult(webSocketConnector, getState, selectedCard, disconnectedCallback) {
  webSocketConnector.sendMessage(
    'VOTING',
    'PRESELECT_FINAL_RESULT',
    { card: selectedCard },
    getState().user.username,
    getState().teamspace.teamSpaceUid,
    getState().user.cookie,
    disconnectedCallback,
  )
}

export const preselectFinalResultSuccess = (selectedCard) => (dispatch, getState) => {
  const { selectedLayout } = getState().teamspace
  let cardIndex = selectedLayout.cardLayoutList.findIndex((card) => card.title === selectedCard)
  dispatch({
    type: votingTypes.PRESELECT_FINAL_RESULT_SUCCESS,
    payload: cardIndex,
  })
}
export const selectFinalResult = (selectedCard) => (dispatch, getState) => {
  const { selectedLayout } = getState().teamspace
  let cardIndex = selectedLayout.cardLayoutList.findIndex((card) => card.title === selectedCard)
  dispatch({
    type: votingTypes.SELECT_FINAL_CARD_SUCCESS,
    payload: cardIndex,
  })
}

export const scrollToPreselectedCard = (selectedCard) => (dispatch, getState) => {
  const { selectedLayout } = getState().teamspace
  let cardIndex = selectedLayout.cardLayoutList.findIndex((card) => card.title === selectedCard)

  // 32 is the current width of every card element and 6 is the horizontalMargin
  // the -100 is so the selected card is always somewhat in the middle of the screen
  let xoffset = -100 + cardIndex * (32 + 6)

  dispatch({
    type: votingTypes.SCROLL_TO_PRESELECTED_CARD,
    payload: xoffset,
  })
}

const userCastVoteProgress = (card) => {
  return {
    type: votingTypes.USER_CAST_VOTE_PROGRESS,
    payload: card,
  }
}

export const userCastVoteSuccess = (user, percentage, selectedCard) => {
  return (dispatch, getState) => {
    let own = false
    let card
    if (user === getState().user.uid) {
      own = true
      const { selectedLayout } = getState().teamspace
      card = selectedLayout.cardLayoutList.findIndex((card) => card.title === selectedCard)
    }
    dispatch({
      type: votingTypes.USER_CAST_VOTE_SUCCESS,
      payload: { user, percentage, own, card },
    })
  }
}

const connectWebSocket = (connectedCallback, cookie, teamSpaceUid) => {
  console.log('connect call start')
  WebSocketConnector?.getInstance().connect(connectedCallback, cookie, teamSpaceUid)
}

const userCastVote = (webSocketConnector, getState, selectedCard, disconnectedCallback) => {
  webSocketConnector.sendMessage(
    'VOTING',
    'VOTE_CAST',
    { card: selectedCard },
    getState().user.username,
    getState().teamspace.teamSpaceUid,
    getState().user.cookie,
    disconnectedCallback,
  )
}

const joinSession = (webSocketConnector, getState, disconnectedCallback) => {
  console.log('join session called')
  webSocketConnector.sendMessage(
    'SESSION',
    'JOIN',
    {},
    getState().user.username,
    getState().teamspace.teamSpaceUid,
    getState().user.cookie,
    disconnectedCallback,
  )
}

const leaveSession = async (webSocketConnector, getState, disconnectedCallback) => {
  console.log('leave session called')
  await webSocketConnector.unsubscribe()
  await webSocketConnector.sendMessage(
    'SESSION',
    'LEAVE',
    {},
    getState().user.username,
    getState().teamspace.teamSpaceUid,
    getState().user.cookie,
    disconnectedCallback,
  )
}

const leaveEstimation = (webSocketConnector, getState, disconnectedCallback) => {
  webSocketConnector.sendMessage(
    'ESTIMATION',
    'LEAVE',
    {},
    getState().user.username,
    getState().teamspace.teamSpaceUid,
    getState().user.cookie,
    disconnectedCallback,
  )
}

const disconnectWebSocket = (webSocketConnector) => {
  console.log('disconnect called')
  webSocketConnector.disableReconnect()
  webSocketConnector.disconnect()
}

const changeIssueName = (webSocketConnector, getState, issue, disconnectedCallback) => {
  webSocketConnector.sendMessage(
    'VOTING',
    'CHANGE_ISSUE_NAME',
    { issue: issue },
    getState().user.username,
    getState().teamspace.teamSpaceUid,
    getState().user.cookie,
    disconnectedCallback,
  )
}

const startEstimation = (
  webSocketConnector,
  getState,
  issue,
  wantsToVote,
  disconnectedCallback,
) => {
  webSocketConnector.sendMessage(
    'VOTING',
    'START',
    { issue: issue, wantsToVote: wantsToVote },
    getState().user.username,
    getState().teamspace.teamSpaceUid,
    getState().user.cookie,
    disconnectedCallback,
  )
}

export const joinEstimation = (webSocketConnector, getState, disconnectedCallback) => {
  webSocketConnector.sendMessage(
    'ESTIMATION',
    'JOIN',
    {},
    getState().user.username,
    getState().teamspace.teamSpaceUid,
    getState().user.cookie,
    disconnectedCallback,
  )
}

const finishVoting = (webSocketConnector, getState, disconnectedCallback) => {
  webSocketConnector.sendMessage(
    'VOTING',
    'FINISH',
    {},
    getState().user.username,
    getState().teamspace.teamSpaceUid,
    getState().user.cookie,
    disconnectedCallback,
  )
}

const finishEstimation = (webSocketConnector, getState, chosenCardTitle, disconnectedCallback) => {
  webSocketConnector.sendMessage(
    'ESTIMATION',
    'FINISH',
    // fix this, once chosing the card is implemented in ResultScreen
    { card: chosenCardTitle },
    getState().user.username,
    getState().teamspace.teamSpaceUid,
    getState().user.cookie,
    disconnectedCallback,
  )
}

export const resetVotingState = () => {
  return {
    type: votingTypes.RESET_VOTING_STATE,
  }
}

export const getTeamSpaceInvite = async (teamSpaceUid) => {
  try {
    const response = await Api.get(urls.teamSpaceInvite.format(teamSpaceUid))
    return response.data
  } catch (e) {
    console.log('teamspaceInvite' + e)
    return 'TeamSpace Invite could not be generated. Sorry'
  }
}

export const teamMatesUpdate = (teammates) => async (dispatch) => {
  const list = teammates.map((mate) => {
    // We don't want the photoPath to be available. Always load the image with the created URL
    if (mate.userId)
      return {
        ...mate,
        image: Api.defaults.baseURL + mate.photoPath,
        photoPath: undefined,
      }
    else return { ...mate, image: null, name: null }
  })
  dispatch({
    type: votingTypes.TEAMMATE_STATE_CHANGE,
    payload: [{ name: 'invite' }, ...list],
  })
}

export const refreshUser = (updatedUserData) => async (dispatch, getState) => {
  if (getState().user.userId === updatedUserData.userUid) {
    dispatch({
      type: userTypes.REFRESH_USER,
      payload: updatedUserData,
    })
  }

  const teammatesFromState = getState().voting.teammates.map((mate) => {
    if (mate.userId === updatedUserData.userUid)
      return {
        ...mate,
        nickName: updatedUserData.nickName,
      }
    else return { ...mate }
  })

  dispatch({
    type: votingTypes.TEAMMATE_STATE_CHANGE,
    payload: teammatesFromState,
  })
  dispatch(getInvitation())
}

export const licenseUpdate = (message) => async (dispatch) => {
  dispatch({
    type: userTypes.LICENSE_UPDATED,
    payload: 'updated',
  })
}

export const refreshTeamSpace = (updatedTeamSpace) => async (dispatch) => {
  dispatch({
    type: teamSpaceTypes.REFRESH_TEAMSPACE,
    payload: updatedTeamSpace,
  })
}

export const refreshIssue = (updatedIssue) => async (dispatch) => {
  dispatch({
    type: votingTypes.REFRESH_ISSUE,
    payload: updatedIssue,
  })
}

export const refreshUserProfilePhoto = (updatedProfilePhoto) => async (dispatch, getState) => {
  const list = getState().voting.teammates.map((mate) => {
    if (mate.userId === updatedProfilePhoto.userUid)
      return {
        ...mate,
        image: Api.defaults.baseURL + updatedProfilePhoto.photoPath,
        pictureSource: updatedProfilePhoto.photo,
      }
    else return { ...mate }
  })
  dispatch({
    type: votingTypes.TEAMMATE_STATE_CHANGE,
    payload: list,
  })
}

export const infoRecieved = (payload) => (dispatch) => {
  if (payload.type === 'NOTIFY' || payload.type === 'INFORM') {
    dispatch({
      type: messageTypes.SHOW_MESSAGE,
      payload: { message: payload.text },
    })
    return
  }

  if (payload.type === 'DIALOGUE') {
    // Alert.alert('', payload.text)
    alert(payload.text)
  }
}

export const getInvitation = () => async (dispatch, getState) => {
  try {
    const type = backendEnvironments.find((env) => env.url === Api.defaults.baseURL)?.type
    const { teamSpaceUid } = getState().teamspace
    if (!teamSpaceUid) return
    const response = await Api.get(urls.teamSpaceInvite.format(teamSpaceUid), {
      params: {
        environment: type,
      },
    })
    dispatch({
      type: votingTypes.GET_INVITATION,
      payload: response.data,
    })
  } catch (e) {
    console.log('teamspaceInvite' + e)
    return 'TeamSpace Invite could not be generated. Sorry'
  }
}

export const resetCommandFired = (payload) => (dispatch) => {
  dispatch(resetVotingState())
  dispatch(disconnectStart())
  NotificationManager.error(payload.errorMessage)

  // now the initial screen is owner's teamspace list screen.
  // so whenever reset command get thrown user should be
  // navigated to the team space list.
  // NavigationService.reset('TabArea')
}

export const setSelfTeammateOffline = () => async (dispatch, getState) => {
  dispatch({
    type: votingTypes.SET_TEAMMATE_OFFLINE,
    payload: getState().user.uid,
  })
}

export const setSelfOffline = () => (dispatch) => {
  // Set myself as offline in teammate list
  dispatch(setSelfTeammateOffline())
}

export const updateShownCardId = (id) => (dispatch) => {
  dispatch({
    type: votingTypes.SHOW_CARD,
    payload: id,
  })
}

// only to navigate user under poker tab
export const navigateToExpectedScreen = () => (dispatch) => {
  dispatch({ type: votingTypes.NAVIGATE_TO_EXPECTED_SCREEN })
}

export const showFeedbackForm = (flag) => (dispatch) => {
  dispatch({ type: votingTypes.SHOW_FEEDBACK_FORM, payload: flag })
}

export const closeIssuePopupView = (flag) => (dispatch) => {
  dispatch({ type: votingTypes.SHOW_CLOSE_ISSUE_POPUP, payload: flag })
}

export const forceExit = (flag) => (dispatch) => {
  dispatch({ type: votingTypes.FORCE_EXIT_FROM_RESULT, payload: flag })
}

const estimationLayoutSelect = (
  webSocketConnector,
  getState,
  selectedCardLayout,
  type,
  disconnectedCallback,
) => {
  webSocketConnector.sendMessage(
    'LAYOUT',
    '',
    {
      name: selectedCardLayout.name,
      estimationChange: type,
    },
    getState().user.username,
    getState().teamspace.teamSpaceUid,
    getState().user.cookie,
    disconnectedCallback,
  )
}

export const estimationLayoutSelectStart = (selectedCardLayout, estimationChange) => {
  console.log(selectedCardLayout)
  return (dispatch, getState) => {
    estimationLayoutSelect(
      WebSocketConnector?.getInstance(),
      getState,
      selectedCardLayout,
      estimationChange,
      (dispatch) => {
        dispatch(calcWebSocketState())
      },
    )
    dispatch(estimationLayoutSelectProgress(selectedCardLayout))
  }
}

const estimationLayoutSelectProgress = (selectedCardLayout) => {
  return {
    type: votingTypes.ESTIMATION_LAYOUT_SELECT_PROGRESS,
    payload: {},
  }
}

export const fetchLayoutCards = (layout) => {
  return Api.get(urls.layoutCardsList.format(layout))
}

export const changeScrumMasterState = (isScrumMaster) => {
  return {
    type: votingTypes.CHANGE_SCRUM_MASTER_STATE,
    payload: isScrumMaster,
  }
}

export const showResult = (setResultScreenStatus) => {
  return {
    type: votingTypes.SHOW_RESULT,
    payload: setResultScreenStatus,
  }
}

export const setLicenseScreen = (setLicenseScreenStatus) => {
  return {
    type: votingTypes.SET_LICENSE_SCREEN,
    payload: setLicenseScreenStatus,
  }
}
