import { memo, useEffect } from 'react'
import { useSnackbar } from 'react-simple-snackbar'

function MessageBar({ message, marginLeft }) {
  const options = {
    position: 'top-center',
    style: {
      backgroundColor: '#d9dada',
      color: '#3a3a39',
      marginTop: '90px',
      marginLeft: marginLeft,
      width: '100%',
      textAlign: 'center',
    },
    closeStyle: {
      color: '#3a3a39',
    },
  }
  const [openSnackbar] = useSnackbar(options)
  useEffect(() => {
    if (message) openSnackbar(message)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message])
  return null
}
export default memo(MessageBar)
