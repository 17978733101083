export const BACKEND_CALL_START = 'BACKEND_CALL_START';
export const BACKEND_CALL_INPROGRESS = 'BACKEND_CALL_INPROGRESS';
export const BACKEND_CALL_SUCCESS = 'BACKEND_CALL_SUCCESS';
export const BACKEND_CALL_FAILED = 'BACKEND_CALL_FAILED';
export const VERSION_NOT_ALLOWED = 'VERSION_NOT_ALLOWED';
export const BACKEND_GET_HELP_MESSAGES = 'BACKEND_GET_HELP_MESSAGES';
export const BACKEND_SET_LEARN_MORE_URL = 'BACKEND_SET_LEARN_MORE_URL';
export const BACKEND_SET_PRIVACY_POLICY_URL = 'BACKEND_SET_PRIVACY_POLICY_URL';
export const BACKEND_SET_TERMS_AND_CONDITIONS_URL =
  'BACKEND_SET_TERMS_AND_CONDITIONS_URL';
