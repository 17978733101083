import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import '../node_modules/bootstrap/dist/css/bootstrap.css'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
const format = require('string-format')

window.$ = window.jQuery = require('jquery')

format.extend(String.prototype, {})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)
