import { memo, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { getPromotions, resetLayoutFor } from '../redux/actions/user/userAction'
import * as keysAction from '../redux/actions/keys/keysAction'
import {
  resetVotingState,
  joinSessionStart,
  leaveSessionStart,
} from '../redux/actions/voting/votingAction'
import { changeActiveTeam, teamSpaceInfoStart } from '../redux/actions/teamSpace/teamSpaceAction'
import { getCardSets } from '../redux/actions/payment/paymentAction'
import { keys, sessionkeys, getKeyValue, setKeyValue } from '../services/localStorage'
import { loginwithCookieOrCreateGuest } from '../redux/actions/user/userAction'
import { updateTeamspaceId } from '../redux/actions/keys/keysAction'
import newhistory from '../services/history'
import { useDispatch } from 'react-redux'
import Router from '../router'

function RootContainer() {
  const dispatch = useDispatch()
  const history = useHistory()
  useEffect(() => {
    fetchCookie()
    unloadWebSocket()
  })
  const unloadWebSocket = async () => {
    window.onbeforeunload = () => {
      dispatch(leaveSessionStart())
      dispatch(resetLayoutFor())
    }
  }
  const fetchCookie = async () => {
    const cookie = await dispatch(keysAction.getCookieStart())
    if (cookie) {
      await dispatch(keysAction.storeCookieStart(cookie))
    }
    // const deviceId = await getKeyValue(sessionkeys.deviceId)
    const firebaseUid = await getKeyValue(keys.firebaseUid)
    const providerId = await getKeyValue(keys.providerId)
    const deviceId = sessionStorage.getItem(sessionkeys.deviceId)
    if (deviceId && firebaseUid && cookie) {
      let params = {
        // deviceId,
        deviceId,
        firebaseUid,
        providerId,
      }
      if (newhistory?.location?.search) {
        params.parameterUrl = `https://scrumpanion.com/app/start/${newhistory.location.search}`
      }
      const nextActions = await dispatch(loginwithCookieOrCreateGuest(params))
      dispatch(resetVotingState())
      // reprocessingNotStoredPayments()
      dispatch(getCardSets())
      if (nextActions) {
        const { userProfile, teamSpaceUid } = nextActions
        if (userProfile) {
          // const isGetStarted = await getKeyValue(keys.getStarted)
          // if (isGetStarted) {
          history.push('/profile')
          // }
        }
        if (!userProfile && teamSpaceUid) {
          dispatch(getPromotions())
          dispatch(changeActiveTeam(teamSpaceUid))
          dispatch(teamSpaceInfoStart())
          dispatch(joinSessionStart())
          history.push('/issueScreen')
        } else if (userProfile && teamSpaceUid) {
          dispatch(updateTeamspaceId(teamSpaceUid))
        }
      }
    }
    let wantsToVote = await getKeyValue(keys.wantsToVote)
    if (wantsToVote === undefined || wantsToVote === null) {
      await setKeyValue(keys.wantsToVote, 'true')
    }
  }

  return <Router />
}
export default memo(RootContainer)
