import logo from './images/logo.png'
import angledoubleleft from './images/angle-double-left.svg'
import hamburgMenu from './images/menu.svg'
import loader from './images/loader.svg'
import back from './images/back.svg'
import close from './images/close.svg'
import inviteTeamMember from './images/icons/invite-teammembers-icon.png'
import progressIcon from './images/icons/progressIcon.svg'
import avatar1 from './images/avatars/avatar-01.png'
import avatar2 from './images/avatars/avatar-02.png'
import avatar3 from './images/avatars/avatar-03.png'
import avatar4 from './images/avatars/avatar-04.png'
import avatar5 from './images/avatars/avatar-05.png'
import avatar6 from './images/avatars/avatar-06.png'
import avatar7 from './images/avatars/avatar-07.png'
import avatar8 from './images/avatars/avatar-08.png'
import avatar9 from './images/avatars/avatar-09.png'
import avatar10 from './images/avatars/avatar-10.png'
import avatar11 from './images/avatars/avatar-11.png'
import avatar12 from './images/avatars/avatar-12.png'
import slide00 from './images/swiper/slide-00.png'
import slide01 from './images/swiper/slide-01.png'
import slide02 from './images/swiper/slide-02.png'
import slide03 from './images/swiper/slide-03.png'
import slide04 from './images/swiper/slide-04.png'

import fibonacciPokerBavarianCardH from './images/cards/cover_landscape/bavarian.png'
// import planningPokerIcon from './images/cards/planningPoker.png'
import delegationPokerSet from './images/cards/otherIcons/delegation-poker-set-icon.png'
import fibonacciPokerSet from './images/cards/otherIcons/planning-poker-set-icon.png'
import happinessIndexSet from './images/cards/otherIcons/happiness-index-set-icon.png'
import planningPokerSet from './images/cards/otherIcons/planning-poker-set-icon.png'
import tshirtSizeSet from './images/cards/otherIcons/tshirt-size-set-icon.png'
import delegationPokerCard from './images/cards/cover_portrait/delegation.png'
import fibonacciPokerBavarianCard from './images/cards/cover_portrait/fibonacci-bavarian.png'
import fibonacciPokerPlanetCard from './images/cards/cover_portrait/fibonacci-planet.png'
import planningPokerCard from './images/cards/cover_portrait/planning-poker.png'
import planningPokerBavarianCard from './images/cards/cover_portrait/bavarian.png'
import planningPokerIdeCard from './images/cards/cover_portrait/ide.png'
import planningPokerPlanetCard from './images/cards/cover_portrait/planet.png'
import happinessIndexCard from './images/cards/cover_portrait/happiness.png'
import tshirtSizeCard from './images/cards/cover_portrait/tshirt.png'
import delegationPokerCardH from './images/cards/cover_landscape/delegation.jpg'
import happinessIndexCardH from './images/cards/cover_landscape/happiness.jpg'
import fibonacciPokerPlanetCardH from './images/cards/cover_landscape/planet.png'
import planningPokerCardH from './images/cards/cover_landscape/planning.jpg'
import planningPokerBavarianCardH from './images/cards/cover_landscape/bavarian.png'
import planningPokerIdeCardH from './images/cards/cover_landscape/ide.jpg'
import planningPokerPlanetCardH from './images/cards/cover_landscape/planet.png'
import tshirtSizeCardH from './images/cards/cover_landscape/tshirt.jpg'

import coffeeDefault from './images/icons/coffee/default-coffee.png'
import coffeeIde from './images/icons/coffee/ide-coffee.png'
import coffeePlanet from './images/icons/coffee/planet-coffee.png'
import pictureNotFoundCard from './images/cardset/picture-not-found-card.png'
import pictureNotFoundPortrait from './images/cardset/picture-not-found-portrait.png'
import pictureNotFoundLandscape from './images/cardset/picture-not-found-landscape.png'
import pictureNotFoundLandscapeButton from './images/cardset/picture-not-found-landscape-button.png'

import planningPockerLayout0 from './images/cards/otherIcons/planning-poker-layout-0-icon.jpg'
import happinessIndexLayout0 from './images/cards/otherIcons/happiness-index-layout-0-icon.jpg'
import deletegationPockerLayout0 from './images/cards/otherIcons/delegation-poker-layout-0-icon.jpg'
import tshirtSizeLayout0 from './images/cards/otherIcons/tshirt-size-layout-0-icon.jpg'
import planningPokerIde from './images/cards/otherIcons/planning-poker-ide-icon.jpg'
import planningPokerPlanet from './images/cards/otherIcons/planning-poker-planet-icon.jpg'
import planningPokerBavarian from './images/cards/bavarian/cover_portrait.png'

import card0middle_PPD from './images/cards/planning/0.png'
import card1middle_PPD from './images/cards/planning/1.png'
import card2middle_PPD from './images/cards/planning/2.png'
import card3middle_PPD from './images/cards/planning/3.png'
import card5middle_PPD from './images/cards/planning/5.png'
import card8middle_PPD from './images/cards/planning/8.png'
import card13middle_PPD from './images/cards/planning/13.png'
import card20middle_PPD from './images/cards/planning/20.png'
import card40middle_PPD from './images/cards/planning/40.png'
import card100middle_PPD from './images/cards/planning/100.png'
import cardInfimiddle_PPD from './images/cards/planning/infinity.png'
import cardQuemiddle_PPD from './images/cards/planning/question.png'
import cardCoffemiddle_PPD from './images/cards/planning/break.png'

import card1middle_HID from './images/cards/happiness/1.png'
import card2middle_HID from './images/cards/happiness/2.png'
import card3middle_HID from './images/cards/happiness/3.png'
import card4middle_HID from './images/cards/happiness/4.png'
import card5middle_HID from './images/cards/happiness/5.png'
import card6middle_HID from './images/cards/happiness/6.png'
import card7middle_HID from './images/cards/happiness/7.png'
import card8middle_HID from './images/cards/happiness/8.png'
import card9middle_HID from './images/cards/happiness/9.png'
import card10middle_HID from './images/cards/happiness/10.png'

import card1middle_DPD from './images/cards/delegation/1.png'
import card2middle_DPD from './images/cards/delegation/2.png'
import card3middle_DPD from './images/cards/delegation/3.png'
import card4middle_DPD from './images/cards/delegation/4.png'
import card5middle_DPD from './images/cards/delegation/5.png'
import card6middle_DPD from './images/cards/delegation/6.png'
import card7middle_DPD from './images/cards/delegation/7.png'

import cardLmiddle_TPD from './images/cards/tshirt/L.png'
import cardMmiddle_TPD from './images/cards/tshirt/M.png'
import cardSmiddle_TPD from './images/cards/tshirt/S.png'
import cardXLmiddle_TPD from './images/cards/tshirt/XL.png'
import cardXSmiddle_TPD from './images/cards/tshirt/XS.png'

// planning poker ide
import card0bottom_IDE from './images/cards/ide/0.png'
import card1bottom_IDE from './images/cards/ide/1.png'
import card2bottom_IDE from './images/cards/ide/2.png'
import card3bottom_IDE from './images/cards/ide/3.png'
import card5bottom_IDE from './images/cards/ide/5.png'
import card8bottom_IDE from './images/cards/ide/8.png'
import card13bottom_IDE from './images/cards/ide/13.png'
import card20bottom_IDE from './images/cards/ide/20.png'
import card40bottom_IDE from './images/cards/ide/40.png'
import card100bottom_IDE from './images/cards/ide/100.png'
import cardInfibottom_IDE from './images/cards/ide/infinity.png'
import cardQuebottom_IDE from './images/cards/ide/question.png'
import cardCoffebottom_IDE from './images/cards/ide/break.png'

// planning poker planet
import card0middle_PPP from './images/cards/planet/0.png'
import card1middle_PPP from './images/cards/planet/1.png'
import card2middle_PPP from './images/cards/planet/2.png'
import card3middle_PPP from './images/cards/planet/3.png'
import card5middle_PPP from './images/cards/planet/5.png'
import card8middle_PPP from './images/cards/planet/8.png'
import card13middle_PPP from './images/cards/planet/13.png'
import card20middle_PPP from './images/cards/planet/20.png'
import card40middle_PPP from './images/cards/planet/40.png'
import card100middle_PPP from './images/cards/planet/100.png'
import cardInfimiddle_PPP from './images/cards/planet/infinity.png'
import cardQuemiddle_PPP from './images/cards/planet/question.png'
import cardCoffemiddle_PPP from './images/cards/planet/break.png'

// planning poker bavarian
import card0middle_PPB from './images/cards/bavarian/0.png'
import card1middle_PPB from './images/cards/bavarian/1.png'
import card2middle_PPB from './images/cards/bavarian/2.png'
import card3middle_PPB from './images/cards/bavarian/3.png'
import card5middle_PPB from './images/cards/bavarian/5.png'
import card8middle_PPB from './images/cards/bavarian/8.png'
import card13middle_PPB from './images/cards/bavarian/13.png'
import card20middle_PPB from './images/cards/bavarian/20.png'
import card40middle_PPB from './images/cards/bavarian/40.png'
import card100middle_PPB from './images/cards/bavarian/100.png'
import cardInfimiddle_PPB from './images/cards/bavarian/infinity.png'
import cardQuemiddle_PPB from './images/cards/bavarian/question.png'
import cardCoffemiddle_PPB from './images/cards/bavarian/break.png'

// fibonacci poker bavarian
import card0middle_FPB from './images/cards/bavarian/0.png'
import card1middle_FPB from './images/cards/bavarian/1.png'
import card2middle_FPB from './images/cards/bavarian/2.png'
import card3middle_FPB from './images/cards/bavarian/3.png'
import card5middle_FPB from './images/cards/bavarian/5.png'
import card8middle_FPB from './images/cards/bavarian/8.png'
import card13middle_FPB from './images/cards/bavarian/13.png'
import card21middle_FPB from './images/cards/bavarian/20.png'
import card34middle_FPB from './images/cards/bavarian/40.png'
import card55middle_FPB from './images/cards/bavarian/100.png'
import card89middle_FPB from './images/cards/bavarian/infinity.png'

// fibonacci poker planet
import card0middle_FPP from './images/cards/planet/0.png'
import card1middle_FPP from './images/cards/planet/1.png'
import card2middle_FPP from './images/cards/planet/2.png'
import card3middle_FPP from './images/cards/planet/3.png'
import card5middle_FPP from './images/cards/planet/5.png'
import card8middle_FPP from './images/cards/planet/8.png'
import card13middle_FPP from './images/cards/planet/13.png'
import card21middle_FPP from './images/cards/planet/20.png'
import card34middle_FPP from './images/cards/planet/40.png'
import card55middle_FPP from './images/cards/planet/100.png'
import card89middle_FPP from './images/cards/planet/infinity.png'

const images = {
  logo,
  angledoubleleft,
  hamburgMenu,
  loader,
  back,
  close,
  inviteTeamMember,
  progressIcon,
  avatars: [
    avatar1,
    avatar2,
    avatar3,
    avatar4,
    avatar5,
    avatar6,
    avatar7,
    avatar8,
    avatar9,
    avatar10,
    avatar11,
    avatar12,
  ],
  swiper: [slide00, slide01, slide02, slide03, slide04],
}
export const cards = {
  ulassaCard: {
    // planningPoker: { icon: planningPokerIcon },
  },
  layoutIcons: {
    coffeeDefault,
    coffeeIde,
    coffeePlanet,
    pictureNotFoundCard,
    pictureNotFoundPortrait,
    pictureNotFoundLandscape,
    pictureNotFoundLandscapeButton,
  },
  cardsetLayout: {
    planningPockerLayout0,
    happinessIndexLayout0,
    deletegationPockerLayout0,
    tshirtSizeLayout0,
    planningPokerIde,
    planningPokerPlanet,
    planningPokerBavarian,
  },
  // planning poker default
  PLANNING_POKER_DEFAULT: {
    card0middle: card0middle_PPD,
    card1middle: card1middle_PPD,
    card2middle: card2middle_PPD,
    card3middle: card3middle_PPD,
    card5middle: card5middle_PPD,
    card8middle: card8middle_PPD,
    card13middle: card13middle_PPD,
    card20middle: card20middle_PPD,
    card40middle: card40middle_PPD,
    card100middle: card100middle_PPD,
    cardInfimiddle: cardInfimiddle_PPD,
    cardQuemiddle: cardQuemiddle_PPD,
    cardCoffemiddle: cardCoffemiddle_PPD,
  },
  // happiness index default
  HAPPINESS_INDEX_DEFAULT: {
    card1middle: card1middle_HID,
    card2middle: card2middle_HID,
    card3middle: card3middle_HID,
    card4middle: card4middle_HID,
    card5middle: card5middle_HID,
    card6middle: card6middle_HID,
    card7middle: card7middle_HID,
    card8middle: card8middle_HID,
    card9middle: card9middle_HID,
    card10middle: card10middle_HID,
  },
  // delegation poker default
  DELEGATION_POKER_DEFAULT: {
    card1middle: card1middle_DPD,
    card2middle: card2middle_DPD,
    card3middle: card3middle_DPD,
    card4middle: card4middle_DPD,
    card5middle: card5middle_DPD,
    card6middle: card6middle_DPD,
    card7middle: card7middle_DPD,
  },
  // Tshirt poker default
  TSHIRT_SIZE_DEFAULT: {
    cardLmiddle: cardLmiddle_TPD,
    cardMmiddle: cardMmiddle_TPD,
    cardSmiddle: cardSmiddle_TPD,
    cardXLmiddle: cardXLmiddle_TPD,
    cardXSmiddle: cardXSmiddle_TPD,
  },
  // planning poker ide
  PLANNING_POKER_IDE: {
    card0bottom: card0bottom_IDE,
    card1bottom: card1bottom_IDE,
    card2bottom: card2bottom_IDE,
    card3bottom: card3bottom_IDE,
    card5bottom: card5bottom_IDE,
    card8bottom: card8bottom_IDE,
    card13bottom: card13bottom_IDE,
    card20bottom: card20bottom_IDE,
    card40bottom: card40bottom_IDE,
    card100bottom: card100bottom_IDE,
    cardInfibottom: cardInfibottom_IDE,
    cardQuebottom: cardQuebottom_IDE,
    cardCoffebottom: cardCoffebottom_IDE,
  },
  // planning poker planet
  PLANNING_POKER_PLANET: {
    card0middle: card0middle_PPP,
    card1middle: card1middle_PPP,
    card2middle: card2middle_PPP,
    card3middle: card3middle_PPP,
    card5middle: card5middle_PPP,
    card8middle: card8middle_PPP,
    card13middle: card13middle_PPP,
    card20middle: card20middle_PPP,
    card40middle: card40middle_PPP,
    card100middle: card100middle_PPP,
    cardInfimiddle: cardInfimiddle_PPP,
    cardQuemiddle: cardQuemiddle_PPP,
    cardCoffemiddle: cardCoffemiddle_PPP,
  },
  // planning poker bavarian
  PLANNING_POKER_BAVARIAN: {
    card0middle: card0middle_PPB,
    card1middle: card1middle_PPB,
    card2middle: card2middle_PPB,
    card3middle: card3middle_PPB,
    card5middle: card5middle_PPB,
    card8middle: card8middle_PPB,
    card13middle: card13middle_PPB,
    card20middle: card20middle_PPB,
    card40middle: card40middle_PPB,
    card100middle: card100middle_PPB,
    cardInfimiddle: cardInfimiddle_PPB,
    cardQuemiddle: cardQuemiddle_PPB,
    cardCoffemiddle: cardCoffemiddle_PPB,
  },
  // fibonacci poker bavarian
  FIBONACCI_BAVARIAN: {
    card0middle: card0middle_FPB,
    card1middle: card1middle_FPB,
    card2middle: card2middle_FPB,
    card3middle: card3middle_FPB,
    card5middle: card5middle_FPB,
    card8middle: card8middle_FPB,
    card13middle: card13middle_FPB,
    card21middle: card21middle_FPB,
    card34middle: card34middle_FPB,
    card55middle: card55middle_FPB,
    card89middle: card89middle_FPB,
  },
  // fibonacci poker planet
  FIBONACCI_PLANET: {
    card0middle: card0middle_FPP,
    card1middle: card1middle_FPP,
    card2middle: card2middle_FPP,
    card3middle: card3middle_FPP,
    card5middle: card5middle_FPP,
    card8middle: card8middle_FPP,
    card13middle: card13middle_FPP,
    card21middle: card21middle_FPP,
    card34middle: card34middle_FPP,
    card55middle: card55middle_FPP,
    card89middle: card89middle_FPP,
  },
  cardset: {
    delegationPokerSet,
    fibonacciPokerSet,
    happinessIndexSet,
    planningPokerSet,
    tshirtSizeSet,
    delegationPokerCard,
    fibonacciPokerBavarianCard,
    fibonacciPokerPlanetCard,
    planningPokerCard,
    planningPokerBavarianCard,
    planningPokerIdeCard,
    planningPokerPlanetCard,
    happinessIndexCard,
    tshirtSizeCard,
    delegationPokerCardH,
    happinessIndexCardH,
    fibonacciPokerBavarianCardH,
    fibonacciPokerPlanetCardH,
    planningPokerCardH,
    planningPokerBavarianCardH,
    planningPokerIdeCardH,
    planningPokerPlanetCardH,
    tshirtSizeCardH,
  },
}
export default images
