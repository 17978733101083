import * as votingTypes from '../actions/voting/votingTypes'
import * as userTypes from '../actions/user/userTypes'
import history from '../../services/history'
// import NavigationService from '../../Navigation/NavigationService'

const votingInitalState = {
  // state of the webSocket and message broker connection
  webSocketState: 'UNDEFINED',
  // state of the voting, that is received from the Server
  votingState: 'UNDEFINED',
  oldVotingState: 'UNDEFINED',
  // initial percentage of voted users is 0
  votingPercentage: '0%',
  // the selectedCardId decides which card is selected in the Card selection component
  selectedCardId: undefined,
  // the shownCardId is the card, that is shown, but not necessarily the one, that is selected
  shownCardId: 0,
  // the percentages the cards scored in a voting
  cardPercentages: undefined,
  // we presume, that in the beginning no estimation is running
  joinButton: false,
  // no card is selected by default in ResultScreen
  preselectedCard: -1,
  // scroll to the given card in result screen
  scrollOffsetPreselectedCard: 0,
  // connected teammates
  teammates: [],
  // if user is not on poker tab and got show_command than
  // the screen name will be stored in this variable
  // screen to show when issue, estimation, result screen displayed
  screenToShow: null,
  // show feedback form flag to handle showing feedback form
  showFeedForm: false,
  // show issue close popup based on this flag
  showIssueClosePopup: false,
  // show issue close popup or not
  forceExitFromResult: false,
  // show which user voted which user not
  voters: [],
  allowShowResultScreen: false,
  resultScreenMessage: 'UNDEFINED',
}

const mapVotingStateToScreen = {
  IDLE: '/issueScreen',
  VOTING: '/estimationScreen',
  SUMMARY: '/resultScreen',
  UNDEFINED: 'SelectEnvironment',
  leaveSession: false,
}

function navigateToScreen(votingState) {
  if (mapVotingStateToScreen[votingState]) {
    history.push(mapVotingStateToScreen[votingState])
  }
}

function calcParticipWithVotings(parsedPayloadParam) {
  let particWithVotingsObj = new Map()
  let participantsWithVotings = new Map()
  particWithVotingsObj = parsedPayloadParam.participantsVotings
  Object.keys(particWithVotingsObj).forEach((userId) => {
    participantsWithVotings.set(userId, particWithVotingsObj[userId])
  })
  return participantsWithVotings
}

function calcPercentages(parsedPayloadParam) {
  let cardPercentages = new Map()
  let cardPercObj = parsedPayloadParam.cardPercentages
  Object.keys(cardPercObj).forEach((objectkey) => {
    cardPercentages.set(objectkey, cardPercObj[objectkey])
  })
  return cardPercentages
}

export default function votingReducer(state = votingInitalState, action) {
  let chosenValue
  let parsedpayload
  let teammates
  let meTeamMate
  let meTeamMateIndex
  switch (action.type) {
    // events triggered from ui
    // WEBSOCKET CONNECTION
    case votingTypes.CONNECT_START:
      console.log(votingTypes.CONNECT_START)
      return state
    case votingTypes.CONNECT_PROGRESS:
      console.log(votingTypes.CONNECT_PROGRESS)
      state = {
        ...state,
        webSocketState: action.payload,
      }
      return state
    // WEBSOCKET DISCONNECT
    case votingTypes.DISCONNECT_START:
      console.log(votingTypes.DISCONNECT_START)
      return state

    // JOIN SESSION
    case votingTypes.JOIN_SESSION_START:
      console.log(votingTypes.JOIN_SESSION_START)
      return state
    case votingTypes.JOIN_SESSION_PROGRESS:
      return state

    case votingTypes.SHOW_SCREEN:
      console.log(votingTypes.SHOW_SCREEN)
      if (action.payload.screen === 'VOTING' || action.payload.screen === 'SUMMARY') {
        state = {
          ...state,
          issue: action.payload.issue,
        }
      } else {
        state = {
          ...state,
        }
      }
      if (
        ['/resultScreen', '/estimationScreen', '/issueScreen'].includes(history.location.pathname)
      ) {
        if (state.showFeedForm && action.payload.screen === 'IDLE') {
          history.push('/')
          // NavigationService.navigate('FeedbackForm')
        } else {
          setTimeout(() => {
            // Need to add timeout due to error “Error: You may not call store.getState() while the reducer is executing.”
            navigateToScreen(action.payload.screen)
          }, 100)
        }
      } else {
        state = { ...state, screenToShow: action.payload.screen }
      }
      return state

    // START ESTIMATION
    case votingTypes.VOTING_START_PROGRESS:
      console.log(votingTypes.VOTING_START_PROGRESS)
      return state

    // JOIN ESTIMATION
    case votingTypes.ESTIMATION_JOIN:
      console.log(votingTypes.ESTIMATION_JOIN)
      return state

    // FINISH ESTIMATION
    case votingTypes.ESTIMATION_FINISH:
      console.log(votingTypes.ESTIMATION_FINISH)
      return state

    case votingTypes.USER_CAST_VOTE_PROGRESS:
      state = {
        ...state,
        shownCardId: action.payload,
      }
      return state

    // events triggered from Backend
    // WEBSOCKET CONNECTION
    case votingTypes.CONNECTION_STATUS_CHANGE:
      state = {
        ...state,
        webSocketState: action.payload,
      }
      return state

    // WEBSOCKET DISCONNECT
    case votingTypes.DISCONNECT_SUCCESS:
      console.log(votingTypes.DISCONNECT_SUCCESS)
      state = {
        ...state,
        webSocketState: action.payload,
        oldVotingState: state.votingState,
        votingState: 'UNDEFINED',
        participantsWithVotings: undefined,
        cardPercentages: undefined,
        percentage: 0,
        voters: [],
      }
      return state
    // JOIN SESSION
    case votingTypes.CHANGE_STATE:
      console.log(votingTypes.CHANGE_STATE)
      state = {
        ...state,
        oldVotingState: state.votingState,
        votingState: action.payload.state,
      }
      return state
    case votingTypes.SET_VOTING_RESULT:
      state = {
        ...state,
        average: action.payload.average,
        totalVotes: action.payload.totalVotes,
        participantsWithVotings: action.payload.participantsWithVotings,
        cardPercentages: action.payload.cardPercentages,
      }
      return state
    case votingTypes.JOIN_SESSION_FAILED:
      console.log(votingTypes.JOIN_SESSION_FAILED, action)
      alert(action.payload)
      return state
    case votingTypes.ENABLE_START_BUTTON:
      state = {
        ...state,
        joinButton: false,
      }
      return state
    // START VOTING
    case votingTypes.VOTING_START_SUCCESS:
      state = {
        ...state,
        oldVotingState: state.votingState,
        votingState: 'VOTING',
      }
      navigateToScreen(state)
      return state
    case votingTypes.ENABLE_JOIN_BUTTON:
      state = {
        ...state,
        joinButton: true,
      }
      return state
    case votingTypes.VOTING_START_FAILED:
      console.log(votingTypes.VOTING_START_FAILED, action)
      alert(action.payload)
      return state
    case votingTypes.SELECT_CARD:
      state = {
        ...state,
        selectedCardId: action.payload,
      }
      return state
    case votingTypes.USER_CAST_VOTE_SUCCESS:
      state = {
        ...state,
        percentage: action.payload.percentage,
      }
      // if user in message is self, then set state to selected card so all devices can update to the selected device
      if (action.payload.own) {
        state = {
          ...state,
          selectedCardId: action.payload.card,
        }
      }
      return state
    case votingTypes.SHOW_CARD:
      state = {
        ...state,
        shownCardId: action.payload,
      }
      return state
    case votingTypes.PRESELECT_FINAL_RESULT_SUCCESS:
      state = {
        ...state,
        preselectedCard: action.payload,
      }
      return state
    case votingTypes.SELECT_FINAL_CARD_SUCCESS:
      state = {
        ...state,
        selectedFinalCard: action.payload,
      }
      return state
    // VOTING
    case votingTypes.VOTING_FINISH_SUCCESS:
      parsedpayload = JSON.parse(action.payload.payload)
      state = {
        ...state,
        oldVotingState: state.votingState,
        votingState: 'SUMMARY',
        selectedCardId: undefined,
        average: parsedpayload.average,
        totalVotes: parsedpayload.totalVotes,
        participantsWithVotings: calcParticipWithVotings(parsedpayload),
        cardPercentages: calcPercentages(parsedpayload),
        voters: parsedpayload.voters,
      }
      navigateToScreen(state)
      return state

    // JOIN ESTIMATION
    case votingTypes.ESTIMATION_JOIN_SUCCESS:
      console.log(votingTypes.ESTIMATION_JOIN_SUCCESS)
      state = {
        ...state,
      }
      navigateToScreen(state.votingState)
      return state
    case votingTypes.SET_VOTING_PROGRESS:
      state = {
        ...state,
        userInSession: action.payload.userAmountInEstimation,
        userVoted: action.payload.userAmountHasVoted,
        votingPercentage: action.payload.votingPercentage,
        voters: action.payload.voters,
      }
      return state
    case votingTypes.ESTIMATION_JOIN_FAILED:
      console.log(votingTypes.ESTIMATION_JOIN_FAILED)
      alert(action.payload)
      return state

    // FINISH ESTIMATION
    case votingTypes.ESTIMATION_FINISH_SUCCESS:
      console.log(votingTypes.ESTIMATION_FINISH_SUCCESS)
      // once available the selected card should be set in here for the voted issue
      chosenValue = JSON.parse(action.payload.payload).chosenValue
      alert('Your Scrum master chose this result ' + chosenValue)
      state = {
        ...state,
        oldVotingState: state.votingState,
        votingState: 'IDLE',
        participantsWithVotings: undefined,
        cardPercentages: undefined,
        percentage: 0,
        voters: [],
      }
      navigateToScreen(state)
      return state
    case votingTypes.ESTIMATION_FINISH_FAILED:
      console.log(votingTypes.ESTIMATION_FINISH_FAILED, action)
      alert(action.payload)
      return state

    case votingTypes.SESSION_USER_JOINED:
      console.log(votingTypes.SESSION_USER_JOINED)
      state = {
        ...state,
        percentage: JSON.parse(action.payload.payload).votingPercentage,
      }
      return state

    case votingTypes.ESTIMATION_USER_JOINED:
      console.log(action.type)
      state = {
        ...state,
        percentage: action.payload,
      }
      return state
    case votingTypes.ESTIMATION_USER_LEFT:
      console.log(action.type)
      state = {
        ...state,
        percentage: action.payload,
      }
      return state
    case votingTypes.ESTIMATION_LEAVE:
      state = {
        ...state,
      }
      return state
    case votingTypes.SESSION_LEAVE:
      console.log(action.type)
      state = {
        ...state,
        oldVotingState: state.votingState,
        votingState: 'UNDEFINED',
        participantsWithVotings: undefined,
        cardPercentages: undefined,
        percentage: 0,
        leaveSession: true,
        voters: [],
      }
      return state

    case votingTypes.RESET_VOTING_STATE:
      return {
        ...votingInitalState,
        webSocketState: state.webSocketState,
      }

    case votingTypes.SET_TEAMMATE_OFFLINE:
      console.log(votingTypes.SET_TEAMMATE_OFFLINE)
      // Originally in here I wanted to set the user itself to OFFLINE
      // but that in turn triggers a new fetch for the user images and they can't
      // be fetched, because the backend is not reachable. That in turn results
      // in a loading icon, which basically serves the same purpose of leting
      // the user know that something is not right here. Once we have the pictures
      // in cache this will actually work as originally intended
      teammates = state.teammates
      if (!teammates.length) {
        return state
      }
      meTeamMate = teammates?.find((mate) => mate.userId === action.payload)
      meTeamMateIndex = teammates.findIndex((mate) => mate.userId === action.payload)
      meTeamMate.connectionState = 'OFFLINE'
      meTeamMate = { ...meTeamMate }
      teammates[meTeamMateIndex] = meTeamMate

      state = {
        ...state,
        teammates,
      }
      return state
    case votingTypes.TEAMMATE_STATE_CHANGE:
      console.log(votingTypes.TEAMMATE_STATE_CHANGE, action)
      state = {
        ...state,
        teammates: action.payload,
      }
      return state
    case votingTypes.SET_LAST_CORRELATION:
      state = {
        ...state,
        lastCorrelationIdRequest: action.payload.request,
        lastCorrelationId: action.payload.correlationId,
        lastCorrelationIdTime: new Date().getTime(),
      }
      return state
    case votingTypes.GET_INVITATION:
      state = {
        ...state,
        invitation: action.payload,
      }
      return state
    case votingTypes.SCROLL_TO_PRESELECTED_CARD:
      return { ...state, scrollOffsetPreselectedCard: action.payload }
    case votingTypes.NAVIGATE_TO_EXPECTED_SCREEN:
      navigateToScreen(state.screenToShow)
      return { ...state, screenToShow: null }
    case votingTypes.SHOW_FEEDBACK_FORM:
      return { ...state, showFeedForm: action.payload }
    case votingTypes.SHOW_CLOSE_ISSUE_POPUP:
      return { ...state, showIssueClosePopup: action.payload }
    case votingTypes.CHANGE_SCRUM_MASTER_STATE:
      return { ...state, userScrumMaster: action.payload }
    case votingTypes.FORCE_EXIT_FROM_RESULT:
      return { ...state, forceExitFromResult: action.payload }
    case votingTypes.SHOW_RESULT:
      return {
        ...state,
        allowShowResultScreen: action.payload.allowShowResultScreen,
        resultScreenMessage: action.payload.message,
        firstMessage: action.payload.firstMessage,
        memberMainMessage: action.payload.memberMainMessage,
        ownerMainMessage: action.payload.ownerMainMessage,
        licensePopupTopMessage: action.payload.licensePopupTopMessage,
        licensePopupMiddleMessage: action.payload.licensePopupMiddleMessage,
        licensePopupBottomMessage: action.payload.licensePopupBottomMessage,
        upgradeButtonMessage: action.payload.upgradeButtonMessage,
        nextButtonMessage: action.payload.nextButtonMessage,
        greatValueMessage: action.payload.greatValueMessage,
        bestChoiceMessage: action.payload.bestChoiceMessage,
        upgradableHigherLicenses: action.payload.upgradableHigherLicenses,
        bestChoiceLicenses: action.payload.bestChoiceLicenses,
      }
    case votingTypes.SET_LICENSE_SCREEN:
      return {
        ...state,
        firstMessage: action.payload.firstMessage,
        memberMainMessage: action.payload.memberMainMessage,
        licensePopupTopMessage: action.payload.licensePopupTopMessage,
        licensePopupMiddleMessage: action.payload.licensePopupMiddleMessage,
        licensePopupBottomMessage: action.payload.licensePopupBottomMessage,
        nextButtonMessage: action.payload.nextButtonMessage,
        greatValueMessage: action.payload.greatValueMessage,
        bestChoiceMessage: action.payload.bestChoiceMessage,
        upgradableHigherLicenses: action.payload.upgradableHigherLicenses,
        bestChoiceLicenses: action.payload.bestChoiceLicenses,
      }
    case votingTypes.REFRESH_ISSUE:
      console.log(action.type)
      state = {
        ...state,
        issue: action.payload.issue,
      }
      return state
    case userTypes.USER_LOGOUT:
      console.log(action.type)
      state = {
        ...votingInitalState,
      }
      return state
    default:
      return state
  }
}
