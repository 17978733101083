import * as messageTypes from '../actions/message/messageTypes'

const initialState = {
  message: undefined,
}

export default function messageReducer(state = initialState, action) {
  let messageData
  switch (action.type) {
    case messageTypes.SHOW_MESSAGE:
      messageData = action.payload
      console.log(messageData, action, 'messageData')
      state = {
        ...state,
        message: messageData.message,
      }
      return state
    default:
      return state
  }
}
