// import middlewares from 'redux-thunk'
// import { createStore, applyMiddleware, compose } from 'redux'
// import { persistStore, persistReducer } from 'redux-persist'
// import storage from 'redux-persist/lib/storage'
// import reducers from '../redux/reducers/rootReducer'
// const persistConfig = {
//   key: 'root',
//   storage,
// }
// const persistedReducer = persistReducer(persistConfig, reducers)
// const store = createStore(persistedReducer, compose(applyMiddleware(middlewares)))
// export const persistor = persistStore(store)
// export default store
import middlewares from 'redux-thunk'
import { createStore, applyMiddleware, compose } from 'redux'
import reducers from '../redux/reducers/rootReducer'
import WebSocketConnector from './webSocketConnector'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(reducers, composeEnhancers(applyMiddleware(middlewares)))
registerDispatchForWebSocketConnector(store.dispatch)

export default store

function registerDispatchForWebSocketConnector(dispatch) {
  const webSocketConnector = WebSocketConnector.getInstance()
  webSocketConnector.addDispatch(dispatch)
}
