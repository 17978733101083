import * as paymentTypes from './paymentTypes'
import Api, { urls } from '../../../services/api'
import { loginwithCookieOrCreateGuest } from '../user/userAction'
import { PACKAGE_NAME } from '../../../services/config'
// import IAP from '../../../Services/InAppPurchase'
// import { showMessage } from '../../../heplers/messagebar'
import { cards } from '../../../assets/images'

export const savePayment = (paymentInformation, key) => async (dispatch) => {
  try {
    dispatch(paymentSaveBefore())
    await Api.post(urls.savePayment, paymentInformation).then((response) => {
      if (response.status === 200) {
        // after stored data in backend start the payment handling
        dispatch(handlePayment(response.data, key))
        return true
      } else {
        dispatch(paymentSaveFailed())
        return false
      }
    })
  } catch (e) {
    console.log('payment save failed :', e)
    return false
  }
}

export const handlePayment = (paymentId, key) => async (dispatch) => {
  try {
    console.log(urls.handlePayment.format(paymentId))
    await Api.post(urls.handlePayment.format(paymentId)).then((response) => {
      if (response.status === 200) {
        // Handling payment information in backend was success. Payment information can now delete from localStorage
        dispatch(removePaymentFromFileSystem(key))
        dispatch(loginwithCookieOrCreateGuest())
        return true
      } else {
        dispatch(paymentSaveFailed())
        return false
      }
    })
  } catch (e) {
    console.log(e)
    return false
  }
}

export const paymentSaveBefore = () => {
  return {
    type: paymentTypes.PAYMENT_SAVE_BEFORE,
    payload: {},
  }
}

export const paymentSaveSuccess = () => {
  return {
    type: paymentTypes.PAYMENT_SAVE_SUCCESS,
    payload: {},
  }
}

export const paymentSaveFailed = () => {
  return {
    type: paymentTypes.PAYMENT_SAVE_FAILED,
    payload: {},
  }
}

export const storePaymentToFileSystem = (paymentInformation) => async (dispatch) => {
  dispatch(paymentSaveFailed)
  const key = 'payment' + Date.now()
  await localStorage.setItem(key, JSON.stringify(paymentInformation))
  dispatch(savePayment(paymentInformation, key))
  return key
}

export const getPaymentFromFileSystem = (key) => async () => {
  await localStorage
    .getItem(key)
    .then((value) => {
      return value
    })
    .catch((error) => {
      console.log(error)
    })
}

export const removePaymentFromFileSystem = (key) => async () => {
  await localStorage
    .removeItem(key)
    .then((value) => {
      //
    })
    .catch((error) => {
      console.log('Payment key remove from localStorage failed :', key, error)
    })
}

export const reprocessingNotStoredPayments = () => async (dispatch) => {
  let allKeys = await localStorage.getAllKeys()
  if (allKeys.length) {
    let filteredKeys = allKeys.filter((key) => key.includes('payment'))
    await Promise.all(
      filteredKeys.map((key) => {
        let value = localStorage.getItem(key).then((value) => {
          dispatch(savePayment(JSON.parse(value), key))
        })
        return { key, value }
      }),
    )
  }
}

export const getPaymentPackages = () => async (dispatch) => {
  try {
    const response = await Api.get(urls.getPackages)
    dispatch(paymentGetPackagesSuccess(response.data))
  } catch (e) {
    console.log(e)
    dispatch(paymentGetPackagesFailed())
  }
}

export const paymentGetPackagesSuccess = (payload) => {
  return {
    type: paymentTypes.PAYMENT_GET_PACKAGES_SUCCESS,
    payload: payload,
  }
}

export const paymentGetPackagesFailed = () => {
  return {
    type: paymentTypes.PAYMENT_GET_PACKAGES_FAILED,
    payload: {},
  }
}

export const cancelPayment = (paymentList) => async (dispatch) => {
  try {
    await Api.delete(urls.cancelPayment, { data: paymentList }).then((response) => {
      if (response.status === 200) {
        dispatch(paymentCancelSuccess(response.status))
        dispatch(loginwithCookieOrCreateGuest())
      } else {
        console.log('payment cancel handled failed because payment status :', response.status)
        dispatch(paymentCancelFailed())
      }
    })
  } catch (e) {
    console.log(e)
    return false
  }
}

export const paymentCancelFailed = () => {
  return {
    type: paymentTypes.PAYMENT_CANCEL_FAILED,
    payload: {},
  }
}

export const paymentCancelSuccess = (payload) => {
  return {
    type: paymentTypes.PAYMENT_CANCEL_SUCCESS,
    payload: payload,
  }
}

export const getCardSets = () => async (dispatch) => {
  try {
    const response = await Api.get(urls.getCardSets)
    dispatch(
      getCardSetsSuccess(
        response.data.map((cardset) => ({
          ...cardset,
          cardSetPaymentName: cardset.cardSetPaymentName
            ? cardset.cardSetPaymentName.toLowerCase()
            : null,
          cardSetImage: cards.cardset[cardset.cardSetImage],
          cardSetImageHorizontal: cards.cardset[cardset.cardSetImageHorizontal],
        })),
      ),
    )
  } catch (e) {
    console.log(e)
    dispatch(getCardSetsFailed())
  }
}

export const getCardSetsFailed = () => {
  return {
    type: paymentTypes.GET_CARDSETS_FAILED,
    payload: {},
  }
}

// this action fetches the information from the apple/play store
// if any product is purchased or not
// and populate the cardsetLayouts based on that
export const getCardSetsSuccess = (payload) => async (dispatch, getState) => {
  const {
    user: { uid },
  } = getState()
  // dispatch to populate the cardsets
  // so that it won't stop issue screen
  dispatch({
    type: paymentTypes.GET_CARDSETS_SUCCESS,
    payload,
  })

  const cardLayouts = payload.filter((item) => item.cardSetOrder > 0)

  // build the layout product ids to get store information
  const layouts = []
  payload.forEach((layout) =>
    layout.cardSetPaymentName
      ? layouts.push(PACKAGE_NAME + '.' + layout.cardSetPaymentName)
      : false,
  )

  // fetch product info, prices
  // const products = await IAP.getProducts(layouts)

  const purchasedLayouts = await getActiveLayout(uid)

  // map the cardset with store information fetched above
  const updatedCardLayouts = cardLayouts.map((card) => {
    //if (!card.cardSetPaymentName) return card;
    let updatedCard = { ...card }
    // const productId = PACKAGE_NAME + '.' + card.cardSetPaymentName

    // check if layout is in the available store products
    // const availableProduct = products?.find((product) => product.productId === productId)
    // if (availableProduct) updatedCard = { ...updatedCard, price: availableProduct.localizedPrice }
    // // check if layout is purchased or not
    // const purchasedLayout = purchasedProducts.find(
    //   product => product.productId === productId
    // );
    // if (purchasedLayout) return { ...updatedCard, purchased: true };
    const purchasedLayout = purchasedLayouts?.data?.find(
      (product) => product.cardSetInfo.name === updatedCard.name,
    )
    if (purchasedLayout) return { ...updatedCard, purchased: true }
    return updatedCard
  })

  dispatch({
    type: paymentTypes.GET_CARDSETS_SUCCESS,
    payload: updatedCardLayouts,
  })
}

export const cardsetLayoutPurchased = (layout) => (dispatch, getState) => {
  const {
    payment: { cardSets },
  } = getState()

  const newLayouts = cardSets.map((cardset) => {
    if (cardset.name === layout.name) return { ...cardset, purchased: true }
    return cardset
  })

  dispatch({
    type: paymentTypes.GET_CARDSETS_SUCCESS,
    payload: newLayouts,
  })
}

export const getActiveLayout = async (uid) => {
  const result = await Api.get(urls.activeLayouts.format(uid))
  return result
}

export const refreshPayment = (paymentList, displayMsg) => async (dispatch) => {
  try {
    const response = await Api.post(urls.refreshPayment, { data: paymentList })
    // const { message, level } = response.data
    // if (message && displayMsg) {
    //   showMessage({
    //     type: level,
    //     text: message,
    //   })
    // }
    return response.data
  } catch (e) {
    console.log(e)
    return false
  }
}
