import * as userTypes from './userTypes'
import Api, { urls, setAuthorization } from '../../../services/api'
import * as teamSpaceTypes from '../teamSpace/teamSpaceTypes'
import * as keysAction from '../keys/keysAction'
import * as votingAction from '../voting/votingAction'
import * as messageTypes from '../message/messageTypes'
import { auth } from '../../../services/firebase'
import { keys, setKeyValue, removeKey } from '../../../services/localStorage'

export const userUpdateSuccess = (response) => {
  return {
    type: userTypes.USER_UPDATE_SUCCESS,
    payload: response,
  }
}
export const updateUserInfo = (user) => async (dispatch, getState) => {
  try {
    const { uid } = getState().user
    const response = await Api.put(`${urls.updateUser}/${uid}`, user)
    dispatch(
      userUpdateSuccess({
        ...getState().user,
        ...response.data,
      }),
    )
  } catch (e) {
    console.log(e)
    return Promise.reject(e)
  }
}
export const updateUserPicture = (picture) => async (dispatch, getState) => {
  try {
    const { uid } = getState().user
    const response = await Api.put(`user/${uid}${urls.userPicbase}`, { picture })
    console.log(response)
  } catch (e) {
    console.log(e)
    return Promise.reject()
  }
}
export const getUserImage = (url) => async () => {
  try {
    const response = await Api.get(url, { responseType: 'arraybuffer' }).then((response) =>
      Buffer.from(response.data, 'binary').toString('base64'),
    )
    return Promise.resolve(`data:image/jpeg;base64,${response}`)
  } catch (e) {
    return Promise.reject()
  }
}
// export const guestLogin = (params) => async (dispatch) => {
//   try {
//     const res = await Api.post(`${urls.userLogin}`, params)
//     params.storedCookie = res.data.userDevice.cookie
//     dispatch({
//       type: teamSpaceTypes.FETCH_USER_TEAMSPACE_SUCCESS,
//       payload: res.data.teamSpaceList,
//     })
//     setAuthorization({
//       username: res.data.userDevice.user.username,
//       password: res.data.userDevice.user.username,
//     })
//     await dispatch(keysAction.storeCookieStart(res.data.userDevice.cookie))
//     await setKeyValue(keys.firebaseUid, params.firebaseUid)
//     await setKeyValue(keys.providerId, params.providerId)
//     const userProfile = await Api.get(`${urls.userDetails}/${res.data.userDevice.user.uid}`)
//     const userParam = {
//       ...userProfile.data,
//       deviceId: params.deviceId,
//       firebaseUid: params.firebaseUid,
//       providerId: params.providerId,
//     }
//     dispatch(userUpdateSuccess(userParam))
//     return Promise.resolve(userParam)
//   } catch (e) {
//     console.log(e)
//     return Promise.reject(e)
//   }
// }
export const loginwithCookieOrCreateGuest = (properties) => async (dispatch) => {
  try {
    properties = { ...properties, SystemName: 'WebApp' }
    const responseJson = await Api.post(urls.userLogin, properties)
    properties.storedCookie = responseJson.data.userDevice.cookie
    // currently username and password are equal
    const successUser = responseJson.data.userDevice
    successUser.profileModification = responseJson?.data?.profileModification
    dispatch(userLoginSuccess(successUser))
    dispatch({
      type: teamSpaceTypes.FETCH_USER_TEAMSPACE_SUCCESS,
      payload: responseJson.data.teamSpaceList,
    })
    dispatch(
      setApiCredentials(
        responseJson.data.userDevice.user.username,
        responseJson.data.userDevice.user.username,
      ),
    )
    await dispatch(keysAction.storeCookieStart(responseJson.data.userDevice.cookie))
    const userProfile = await Api.get(
      `${urls.userDetails}/${responseJson.data.userDevice.user.uid}`,
    )
    const userParam = {
      ...userProfile.data,
      deviceId: properties.deviceId,
      firebaseUid: properties.firebaseUid,
      providerId: properties.providerId,
    }
    dispatch(userUpdateSuccess(userParam))
    await setKeyValue(keys.firebaseUid, properties.firebaseUid)
    await setKeyValue(keys.providerId, properties.providerId)
    return responseJson.data.nextActions
  } catch (e) {
    console.info('checkCookie api' + e)
    // Server error not handled
    await dispatch(keysAction.clearCookieStart())
    // await dispatch(loginwithCookieOrCreateGuest())
  }
}
export const userLoginSuccess = (response) => {
  return {
    type: userTypes.USER_LOGIN_SUCCESS,
    payload: response,
  }
}
export const userLogout = () => async (dispatch) => {
  dispatch(votingAction.leaveSessionStart())
  await auth.signOut()
  await removeKey(keys.firebaseUid)
  await removeKey(keys.providerId)
  dispatch({
    type: userTypes.USER_LOGOUT,
  })
}
export const setApiCredentials = (username, password) => () => {
  setAuthorization({ username, password })
}
export const resetLayoutFor = () => {
  return {
    type: userTypes.RESET_LAYOUT,
    payload: {},
  }
}
export const refreshUserLayout = (payload) => async (dispatch) => {
  dispatch({
    type: userTypes.USER_TYPE,
    payload: payload,
  })
}
export const fetchCurrentUser = () => async (dispatch) => {
  const responseJson = await Api.post(urls.checkCookie)
  dispatch(userLoginSuccess(responseJson.data))
}
export const getPromotions = () => async (dispatch, getState) => {
  try {
    const { uid } = getState().user
    if (!uid) {
      return Promise.resolve()
    }
    await Api.get(urls.getPromotion.format(uid)).then((res) => {
      dispatch(promotionInfoUpdated(res.data))
    })
  } catch (e) {
    dispatch({
      type: messageTypes.SHOW_MESSAGE,
      payload: { message: 'Server error. Could not get promotion code information.' },
    })
  }
}
export const promotionInfoUpdated = (promotionInfo) => {
  return {
    type: userTypes.PROMOTION_INFO_UPDATED,
    payload: promotionInfo,
  }
}
