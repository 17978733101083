export const GET_TEAMS_START = 'GET_TEAMS_START';
export const GET_TEAMS_SUCCESS = 'GET_TEAMS_SUCCESS';
export const GET_TEAMS_PROGRESS = 'GET_TEAMS_PROGRESS';
export const GET_TEAMS_FAILED = 'GET_TEAMS_FAILED';
export const TEAM_CREATE_PROGRESS = 'TEAM_CREATE_PROGRESS';
export const TEAM_CREATE_START = 'TEAM_CREATE_START';
export const TEAM_CREATE_SUCCESS = 'TEAM_CREATE_SUCCESS';
export const TEAM_CREATE_FAILED = 'TEAM_CREATE_FAILED';
export const SET_ACTIVE_TEAM = 'SET_ACTIVE_TEAM';
export const TEAMSPACE_INFO_UPDATE = 'TEAMSPACE_INFO_UPDATE';
export const REFRESH_TEAMSPACE = 'REFRESH_TEAMSPACEE';
export const FETCH_USER_TEAMSPACE_SUCCESS = 'FETCH_USER_TEAMSPACE_SUCCESS';
export const DELETE_TEAMSPACE = 'DELETE_TEAMSPACE';
export const DELETE_TEAMSPACE_SUCCESS = 'DELETE_TEAMSPACE_SUCCESS';
export const TEAM_DELETE_FAILED = 'TEAM_DELETE_FAILED';
export const TEAM_LEAVE_FAILED = 'TEAM_LEAVE_FAILED';
export const SHOW_CARDSET = 'SHOW_CARDSET';
export const GET_CURRENT_LAYOUT = 'GET_CURRENT_LAYOUT';
