import * as paymentTypes from '../actions/payment/paymentTypes'

const paymentInitalState = {
  paymentSaved: undefined,
}

export default function paymentReducer(state = paymentInitalState, action) {
  switch (action.type) {
    case paymentTypes.PAYMENT_SAVE_BEFORE:
      return {
        ...state,
        paymentSaved: undefined,
      }
    case paymentTypes.PAYMENT_SAVE_FAILED:
      return {
        ...state,
        paymentSaved: false,
      }
    case paymentTypes.PAYMENT_SAVE_SUCCESS:
      return {
        ...state,
        paymentSaved: true,
      }
    case paymentTypes.PAYMENT_GET_PACKAGES_SUCCESS:
      return {
        ...state,
        packages: action.payload,
      }
    case paymentTypes.PAYMENT_GET_PACKAGES_FAILED:
      return {
        ...state,
        packages: action.payload,
      }
    case paymentTypes.PAYMENT_CANCEL_SUCCESS:
      return {
        ...state,
      }
    case paymentTypes.PAYMENT_CANCEL_FAILED:
      return {
        ...state,
      }
    case paymentTypes.GET_CARDSETS_SUCCESS:
      return {
        ...state,
        cardSets: action.payload,
      }
    case paymentTypes.GET_CARDSETS_FAILED:
      return {
        ...state,
      }
    case paymentTypes.SHOW_CARDSET:
      state = {
        ...state,
        selectedLayout: action.payload,
      }
      return state
    default:
      return state
  }
}
