import { combineReducers } from 'redux'
import sidebar from './sidebarReducers'
import user from './userReducers'
import keys from './keyReducer'
import backend from './backendReducer'
import teamspace from './teamSpaceReducer'
import voting from './votingReducer'
import payment from './paymentReducer'
import message from './messageReducer'

export default combineReducers({
  sidebar,
  user,
  keys,
  backend,
  teamspace,
  voting,
  payment,
  message,
})
