import React, { lazy, Suspense, useEffect } from 'react'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { NotificationContainer } from 'react-notifications'
// import { PersistGate } from 'redux-persist/integration/react'
import RootContainer from './containers/rootContainer'
import Loader from './components/loader'
import store from './services/store'
// import store, { persistor } from './services/store'
import '../node_modules/react-notifications/lib/notifications.css'
import { createCorrelationInterceptor } from './services/api'
import { sessionkeys } from './services/localStorage'
import { v4 as uuidv4 } from 'uuid'
import history from './services/history'
import SnackbarProvider from 'react-simple-snackbar'

const Navbar = lazy(() => import('./containers/navbar'))
const Footer = lazy(() => import('./containers/footer'))
function App() {
  useEffect(() => {
    createCorrelationInterceptor()
    if (!sessionStorage.getItem(sessionkeys.deviceId)) {
      sessionStorage.setItem(sessionkeys.deviceId, uuidv4())
    }
  })
  return (
    <Provider store={store}>
      <SnackbarProvider>
        {/* <PersistGate persistor={persistor} loading={null}> */}
        <Suspense fallback={<Loader />}>
          <Router history={history}>
            <NotificationContainer />
            <Navbar />
            <RootContainer />
            <Footer />
          </Router>
        </Suspense>
        {/* </PersistGate> */}
      </SnackbarProvider>
    </Provider>
  )
}
export default App
