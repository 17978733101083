export const PAYMENT_SAVE_SUCCESS = 'PAYMENT_SAVE_SUCCESS'
export const PAYMENT_SAVE_FAILED = 'PAYMENT_SAVE_FAILED'
export const PAYMENT_SAVE_BEFORE = 'PAYMENT_SAVE_BEFORE'
export const PAYMENT_GET_PACKAGES = 'PAYMENT_GET_PACKAGES'
export const PAYMENT_GET_PACKAGES_SUCCESS = 'PAYMENT_GET_PACKAGES_SUCCESS'
export const PAYMENT_GET_PACKAGES_FAILED = 'PAYMENT_GET_PACKAGES_FAILED'
export const PAYMENT_CANCEL_SUCCESS = 'PAYMENT_CANCEL_SUCCESS'
export const PAYMENT_CANCEL_FAILED = 'PAYMENT_CANCEL_FAILED'
export const GET_CARDSETS_SUCCESS = 'GET_CARDSETS_SUCCESS'
export const GET_CARDSETS_FAILED = 'GET_CARDSETS_FAILED'
export const SHOW_CARDSET = 'SHOW_CARDSET'
