export const CONNECT_START = 'CONNECT_START'
export const CONNECTION_STATUS_CHANGE = 'CONNECTION_STATUS_CHANGE'

export const JOIN_SESSION_START = 'JOIN_SESSION_START'
export const JOIN_SESSION_PROGRESS = 'JOIN_SESSION_PROGRESS'
export const CHANGE_STATE = 'CHANGE_STATE'
export const JOIN_SESSION_FAILED = 'JOIN_SESSION_FAILED'

export const SESSION_LEAVE = 'SESSION_LEAVE'

export const SET_LAST_CORRELATION = 'SET_LAST_CORRELATION'
export const DISCONNECT_START = 'DISCONNECT_START'
export const CONNECT_PROGRESS = 'CONNECT_PROGRESS'
export const DISCONNECT_PROGRESS = 'DISCONNECT_PROGRESS'
export const DISCONNECT_SUCCESS = 'DISCONNECT_SUCCESS'
export const SET_TEAMMATE_OFFLINE = 'SET_TEAMMATE_OFFLINE'

export const SHOW_SCREEN = 'SHOW_SCREEN'
export const ENABLE_START_BUTTON = 'ENABLE_START_BUTTON'
export const ENABLE_JOIN_BUTTON = 'ENABLE_JOIN_BUTTON'

export const ESTIMATION_JOIN = 'ESTIMATION_JOIN '
export const ESTIMATION_JOIN_PROGRESS = 'ESTIMATION_JOIN_PROGRESS'
export const ESTIMATION_JOIN_SUCCESS = 'ESTIMATION_JOIN_SUCCESS'
export const ESTIMATION_JOIN_FAILED = 'ESTIMATION_JOIN_FAILED'

export const ESTIMATION_FINISH = 'ESTIMATION_FINISH '
export const ESTIMATION_FINISH_PROGRESS = 'ESTIMATION_FINISH_PROGRESS'
export const ESTIMATION_FINISH_SUCCESS = 'ESTIMATION_FINISH_SUCCESS'
export const ESTIMATION_FINISH_FAILED = 'ESTIMATION_FINISH_FAILED'

export const ESTIMATION_LEAVE = 'ESTIMATION_LEAVE'

export const USER_CAST_VOTE_PROGRESS = 'USER_CAST_VOTE_PROGRESS'
export const USER_CAST_VOTE_SUCCESS = 'USER_CAST_VOTE_SUCCESS'

export const SHOW_CARD = 'SHOW_CARD'
export const SELECT_CARD = 'SELECT_CARD'
export const SCROLL_TO_PRESELECTED_CARD = 'SCROLL_TO_PRESELECTED_CARD'
export const SHOW_CARDSET = 'SHOW_CARDSET'
export const ESTIMATION_LAYOUT_SELECT_PROGRESS = 'ESTIMATION_LAYOUT_SELECT_PROGRESS'

export const VOTING_START = 'VOTING_START'
export const VOTING_START_PROGRESS = 'VOTING_START_PROGRESS'
export const VOTING_START_SUCCESS = 'VOTING_START_SUCCESS'
export const VOTING_START_FAILED = 'VOTING_START_FAILED'

export const VOTING_FINISH = 'VOTING_FINISH'
export const VOTING_FINISH_SUCCESS = 'VOTING_FINISH_SUCCESS'
export const VOTING_FINISH_FAILED = 'VOTING_FINISH_FAILED'

export const PRESELECT_FINAL_RESULT_PROGRESS = 'PRESELECT_FINAL_RESULT_PROGRESS'
export const PRESELECT_FINAL_RESULT_SUCCESS = 'PRESELECT_FINAL_RESULT_SUCCESS'
export const SELECT_FINAL_CARD_SUCCESS = 'SELECT_FINAL_CARD_SUCCESS'

export const SESSION_USER_JOINED = 'SESSION_USER_JOINED'

export const SET_VOTING_PROGRESS = 'SET_VOTING_PROGRESS'
export const SET_VOTING_RESULT = 'SET_VOTING_RESULT'
export const ESTIMATION_USER_JOINED = 'ESTIMATION_USER_JOINED'
export const ESTIMATION_USER_LEFT = 'ESTIMATION_USER_LEFT'
export const REFRESH_ISSUE = 'REFRESH_ISSUE'

export const RESET_VOTING_STATE = 'RESET_VOTING_STATE'

export const TEAMMATE_STATE_CHANGE = 'TEAMMATE_STATE_CHANGE'

export const GET_INVITATION = 'GET_INVITATION'

export const NAVIGATE_TO_EXPECTED_SCREEN = 'NAVIGATE_TO_EXPECTED_SCREEN'

export const SHOW_FEEDBACK_FORM = 'SHOW_FEEDBACK_FORM'

export const SHOW_CLOSE_ISSUE_POPUP = 'SHOW_CLOSE_ISSUE_POPUP'

export const FORCE_EXIT_FROM_RESULT = 'FORCE_EXIT_FROM_RESULT'

export const CHANGE_SCRUM_MASTER_STATE = 'CHANGE_SCRUM_MASTER_STATE'

export const SHOW_RESULT = 'SHOW_RESULT'

export const SET_LICENSE_SCREEN = 'SET_LICENSE_SCREEN'
